export default () => ({

    appUrl: "http://localhost:3000/",
    apiUrl: "http://3.210.156.236/api/v1/",

    appUrl: "http://localhost:3000/",

    apiUrl: "https://maya-75311-ruby.b75311.dev.centralindia.az.svc.builder.ai/api/v1",
    //appUrl: "https://maya-75311-ruby.b75311.dev.centralindia.az.svc.builder.ai/",
    currency: "SAR"

});